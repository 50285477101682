import React from "react";
import './tile.css';

class TileGrid extends React.Component {
    render() {
        return <div className='tile'>
                <div className="hero-image">
                    <div className="hero-text">Big Hero Text</div>
                </div>
                <div className="callout-text">Shop Now</div>
            </div>;
    }
}

export default TileGrid;