import {ReactComponent as Logo } from './../Logo.svg';
import React from "react";
import './top-nav-bar.css';

class TopNavBar extends React.Component {
    render() {
        return <div className='top-nav-bar'>
                <Logo title="CIGAR-ASHTRAYS.COM"/>
            </div>;
    }
}

export default TopNavBar;