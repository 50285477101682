import './App.css';
import TopNavBar from "./components/top-nav-bar.js";
import TileGrid from "./components/tile-grid.js";

function App() {
  return (
    <div className="App">
        <TopNavBar />
        <TileGrid />

    </div>
  );
}

export default App;
