import React from "react";
import './tile-grid.css';
import Tile from "./tile.js";

class TileGrid extends React.Component {
    render() {
        return <div className="tile-grid"> 
                {/* <Tile />
                <Tile />
                <Tile />
                <Tile />
                <Tile />
                <Tile />
                <Tile />
                <Tile />
                <Tile />
                <Tile />
                <Tile />
                <Tile />
                <Tile />
                <Tile />
                <Tile />
                <Tile />
                <Tile />
                <Tile /> */}

            </div>;
    }
}

export default TileGrid;